import store from "../store";
import master from "../store/mod/master";

export default [
  {
    path: "/login",
    name: "LoginComponent",
    component: () => import("../components/access/LoginComponent.vue"),
    meta: { requiresAuth: false },
  },

  {
    path: "/recuperar_contrasena",
    name: "RecuperarContrasenaComponent",
    component: () =>
      import("../components/access/RecuperarContrasenaComponent.vue"),
    meta: { requiresAuth: false },
  },

  {
    path: "/cambiar_contrasena",
    name: "CambiarContrasenaComponent",
    component: () =>
      import("../components/access/CambiarContrasenaComponent.vue"),
    meta: { requiresAuth: false },
  },

  {
    path: "/",
    name: "IndexComponent",
    component: () => import("../components/Inicio.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.state.access.idActionsRol.find((e) => ["HOME"].includes(e))) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/empresas",
    name: "Empresas",
    component: () => import("../components/administracion/Empresas.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.state.access.idActionsRol.find((e) => [3].includes(e))) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/usuarios",
    name: "Usuarios",
    component: () =>
      import("../components/administracion/ListaUsuariosComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.state.access.idActionsRol.find((e) => [6].includes(e))) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/permisos",
    name: "Permisos",
    component: () =>
      import("../components/administracion/PermisosComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.state.access.idActionsRol.find((e) => [7].includes(e))) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },
  {
    path: "/privilegios",
    name: "Privilegios",
    component: () =>
      import("../components/administracion/PrivilegiosComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.state.access.idActionsRol.find((e) => [17].includes(e))) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/calendario",
    name: "Calendario",
    component: () => import("../components/Calendario.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.state.access.idActionsRol.find((e) => [23].includes(e))) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/noticias",
    name: "Noticias",
    component: () => import("../components/Noticias.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.state.access.idActionsRol.find((e) => [1].includes(e))) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/plantillas",
    name: "Plantillas",
    component: () => import("../components/administracion/Plantillas.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.state.access.idActionsRol.find((e) => [8].includes(e))) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/datos_empleado",
    name: "Empleado",
    component: () => import("../components/portal/Empleado.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.state.access.idActionsRol.find((e) => [18].includes(e))) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/sol_cambio_datos",
    name: "SolicitudCambioDatos",
    component: () => import("../components/portal/AprobacionCambioDatos.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.state.access.idActionsRol.find((e) => [2].includes(e))) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/sol_vacaciones",
    name: "SolicitudVacaciones",
    component: () => import("../components/portal/AprobacionVacaciones.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.state.access.idActionsRol.find((e) => [19].includes(e))) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/adm_noticias",
    name: "AdministracionNoticias",
    component: () => import("../components/administracion/Noticias.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.state.access.idActionsRol.find((e) => [24].includes(e))) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/empleados",
    name: "ListaEmpleados",
    component: () => import("../components/administracion/ListaEmpleados.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.state.access.idActionsRol.find((e) => [4].includes(e))) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/sol_cambio_datos_emp",
    name: "SolicitudCambioDatosEmp",
    component: () => import("../components/portal/SolCambioDatos.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.state.access.idActionsRol.find((e) => [26].includes(e))) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/sol_cert_emp",
    name: "SolicitudCertificadoEmp",
    component: () => import("../components/portal/SolCertificado.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.state.access.idActionsRol.find((e) => [26].includes(e))) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/sol_vacaciones_emp",
    name: "SolicitudVacacionesEmp",
    component: () => import("../components/portal/SolVacacionesEmp.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.state.access.idActionsRol.find((e) => [26].includes(e))) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/sol_cert",
    name: "SolicitudCertificado",
    component: () => import("../components/portal/AprobacionDocumentos.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.state.access.idActionsRol.find((e) => [2].includes(e))) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },
];
